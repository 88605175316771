import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '135, 0, 0',
		'primary-dark': '92, 3, 3',
		'accent': '255, 96, 54',
		'accent-plus': '37, 34, 23',
	},
});
